<template>
  <div v-if="event" class="goldrush-page">
    <h2>{{ event.name }} #{{ event.drawNumber }}</h2>

    <div v-if="!showNewGRFlow()" class="alert-wrapper">
      <RbAlert variant="info">
        New to Goldrush Online, or an existing Goldrush player?
        <a class="info-link" :href="goldrushSignupLink">Sign-up here</a>.
      </RbAlert>
    </div>
    <div class="event-range-wrapper">
      <EventRange :maximum="maximum" />
    </div>

    <div class="ticket-packages">
      <h4 class="text-regular mb-3">Ticket Packages</h4>
      <TicketPackage
        v-for="ticketPackage of event.ticketPackages"
        :key="ticketPackage.series"
        :ticket-package="ticketPackage"
        :event-id="event.id"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import TicketPackage from '@/components/TicketPackage.vue';
import EventRange from '@/components/EventRange.vue';
import config from '@/config';
import AgeCheckPayment from '@/components/AgeCheckPayment.vue';
import RbAlert from '@/components/RbAlert.vue';

@Component({ components: { TicketPackage, EventRange, AgeCheckPayment, RbAlert } })
export default class Single extends Vue {
  event: Event = this.$store.state.event;
  siteUrl: string = config.RAFFLEBOX_URL || 'https://www.rafflebox1.net';

  get maximum() {
    return this.$store.state.goldrushMasterEvent.numDraws;
  }

  created() {
    // On back button force page refresh.
    window.onpopstate = function () {
      location.reload();
    };
  }

  showNewGRFlow() {
    return config.SHOW_NEW_GR_FLOW_ORGS.includes(this.$store.state.event.organizationId);
  }

  get goldrushSignupLink() {
    return this.$store.getters.goldrushSignupLink;
  }
}
</script>

<style lang="scss" scoped>
.goldrush-page {
  h2 {
    margin: 1rem 0;
  }

  .info-link {
    font-weight: bold;
    color: $metaPurple;
  }

  .rb-alert {
    margin-top: 0;
  }

  .event-range-wrapper {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 48em) {
  .goldrush-page {
    .alert-wrapper,
    .ticket-packages,
    .event-range-wrapper {
      margin-bottom: 2rem;
    }
  }
}
</style>
